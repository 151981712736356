<template>
  <div
    class="flex items-center justify-between flex-col py-5 px-2.5 card col-span-1 relative bg-white mb-12 lg:mb-0"
    :class="`card-id-${card.id}`"
    v-if="card.showCard"
  >
    <div>
      <div class="feature-card-image-wrapper w-20 h-20 flex justify-center">
        <img
          :src="card.icon"
          :alt="`${card.heading}-image`"
          class="card-image"
          v-if="card.icon"
        />
      </div>
      <h4 class="heading mb-4 font-bold text-[#676A6C]">{{ card.heading }}</h4>
      <p class="subheading">{{ card.text }}</p>
    </div>
    <div class="card-footer cursor-pointer" @click="goConfiguration(card.type)">
      {{ $t("homepage.homepage-feature-cards.read-more") }}
      <img src="@/assets/homepage/read-more.svg" alt="read-more" />
    </div>
  </div>
  <div v-else class="">
    <img
      src="@/assets/homepage/featured-image-1.png"
      alt="featured-image"
      class="card-hero-image p-2 lg:p-3.5 mb-12 lg:mb-0"
    />
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  props: {
    card: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const router = useRouter();

    const goConfiguration = (type) => {
      router.push({ name: "configuration", query: { configuration: type } });
    };

    return {
      goConfiguration,
    };
  },
};
</script>


<style lang="scss" scoped>
.feature-card-image-wrapper {
  background-color: #f48120;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px;
}

.card {
  width: 353px;
  height: 353px;
  border-radius: 6px;

  h4 {
    color: #676a6c;
  }

  .subheading {
    color: #989898;
  }
}

.card-image {
  width: 30px;
  height: auto;
}

.card-hero-image {
  border-radius: 50%;
  border: 1px solid #f48120;
}

.heading {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
}

.subheading {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  font-family: "Open Sans";
}

.card-footer {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #f48120;
}

@media (max-width: 1024px) {
  .card-hero-image {
    width: 314px;
    height: 314px;
  }

  .card {
    width: 100%;
    height: 290px;
  }
}
</style>
<template>
  <section class="plans-container pt-10 pb-10 md:pt-16 md:pb-20 px-6">
    <HomepageHeadingSection :heading="$t('homepage.homepage-pricing-plans.heading')"/>
    <div
      class="
        flex flex-col
        sm:flex-row sm:flex-wrap
        items-center
        justify-around
        lg:justify-between
        gap-y-12
        sm:gap-y-4
        gap-4
      "
    >
      <HomepagePlan v-for="plan in plans" :key="plan.id" :plan="plan" />
    </div>
  </section>
</template>

<script>
import { ref, inject } from "vue";
import HomepagePlan from "@/modules/homepage/components/homepage-plan/HomepagePlan.vue";
import HomepageHeadingSection from "@/modules/homepage/components/homepage-heading-section/HomepageHeadingSection.vue";

export default {
  components: {
    HomepagePlan,
    HomepageHeadingSection,
  },

  setup() {
    const t = inject("t");

    const plans = ref([
      {
        id: 1,
        title: t("homepage.homepage-pricing-plan.title-1"),
        defaultAmount: "4.90",
        amount: "1.90",
        amountDescription: t(
          "homepage.homepage-pricing-plan.amount-description"
        ),
        messages: t("homepage.homepage-pricing-plan.message-1"),
        workingHours: t("homepage.homepage-pricing-plan.working-hours-1"),
        location: t("homepage.homepage-pricing-plan.location-1"),
        calls: t("homepage.homepage-pricing-plan.calls-1"),
        callHistory: t("homepage.homepage-pricing-plan.call-history-1"),
        callRecording: null,
        pbx: null,
        freeMinutes: null,
        chat: null,
        vcc: null,
      },
      {
        id: 2,
        title: t("homepage.homepage-pricing-plan.title-2"),
        defaultAmount: "9.90",
        amount: "4.90",
        amountDescription: t(
          "homepage.homepage-pricing-plan.amount-description"
        ),
        messages: t("homepage.homepage-pricing-plan.message-2"),
        workingHours: t("homepage.homepage-pricing-plan.working-hours-2"),
        location: t("homepage.homepage-pricing-plan.location-2"),
        calls: t("homepage.homepage-pricing-plan.calls-2"),
        callHistory: t("homepage.homepage-pricing-plan.call-history-2"),
        callRecording: t("homepage.homepage-pricing-plan.call-recording-2"),
        pbx: t("homepage.homepage-pricing-plan.pbx-2"),
        freeMinutes: null,
        chat: null,
        vcc: null,
      },
      {
        id: 3,
        title: t("homepage.homepage-pricing-plan.title-3"),
        defaultAmount: "19.90",
        amount: "9.90",
        amountDescription: t(
          "homepage.homepage-pricing-plan.amount-description"
        ),
        messages: t("homepage.homepage-pricing-plan.message-3"),
        workingHours: t("homepage.homepage-pricing-plan.working-hours-3"),
        location: t("homepage.homepage-pricing-plan.location-3"),
        calls: t("homepage.homepage-pricing-plan.calls-3"),
        callHistory: t("homepage.homepage-pricing-plan.call-history-3"),
        callRecording: t("homepage.homepage-pricing-plan.call-recording-3"),
        pbx: t("homepage.homepage-pricing-plan.pbx-3"),
        freeMinutes: t("homepage.homepage-pricing-plan.free-minutes-3"),
        chat: null,
        vcc: null,
      },
      {
        id: 4,
        title: t("homepage.homepage-pricing-plan.title-4"),
        defaultAmount: "29.90",
        amount: "24.90",
        amountDescription: t(
          "homepage.homepage-pricing-plan.amount-description"
        ),
        messages: t("homepage.homepage-pricing-plan.message-4"),
        workingHours: t("homepage.homepage-pricing-plan.working-hours-4"),
        location: t("homepage.homepage-pricing-plan.location-4"),
        calls: t("homepage.homepage-pricing-plan.calls-4"),
        callHistory: t("homepage.homepage-pricing-plan.call-history-4"),
        callRecording: t("homepage.homepage-pricing-plan.call-recording-4"),
        pbx: t("homepage.homepage-pricing-plan.pbx-4"),
        freeMinutes: t("homepage.homepage-pricing-plan.free-minutes-4"),
        chat: t("homepage.homepage-pricing-plan.chat-4"),
        vcc: t("homepage.homepage-pricing-plan.vcc-4"),
      },
    ]);

    return {
      plans,
    };
  },
};
</script>

<style lang="scss" scoped>
.plans-container {
  max-width: 1200px;
  margin: 0 auto;
}

.divider {
  width: 60px;
  margin: 0 auto 50px;
  border-bottom: 1px solid #17b191;
}
</style>
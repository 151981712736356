<template>
  <div class="plan-container relative">
    <div
      class="h-20 plan-header text-white justify-center uppercase flex items-center"
    >
      {{ plan.title }}
    </div>
    <div class="pt-8 pb-10 flex flex-col justify-center">
      <p class="plan-old-amount uppercase relative inline-block">
        {{ plan.defaultAmount }}
      </p>
      <p class="plan-amount uppercase mt-2.5">{{ plan.amount }}</p>
      <p class="plan-text-content text-sm mb-3">{{ plan.amountDescription }}</p>
      <p class="plan-text-content text-sm mb-1">{{ plan.messages }}</p>
      <p class="plan-text-content text-sm mb-1">{{ plan.workingHours }}</p>
      <p class="plan-text-content text-sm mb-1">{{ plan.location }}</p>
      <p class="plan-text-content text-sm mb-1">{{ plan.calls }}</p>
      <p class="plan-text-content text-sm mb-1">{{ plan.callHistory }}</p>
      <p class="plan-text-content text-sm mb-1" v-if="plan.callRecording">
        {{ plan.callRecording }}
      </p>
      <p class="plan-text-content text-sm mb-1" v-if="plan.pbx">
        {{ plan.pbx }}
      </p>
      <p class="plan-text-content text-sm mb-1" v-if="plan.freeMinutes">
        {{ plan.freeMinutes }}
      </p>
      <p class="plan-text-content text-sm mb-1" v-if="plan.chat">
        {{ plan.chat }}
      </p>
      <p class="plan-text-content text-sm mb-1" v-if="plan.vcc">
        {{ plan.vcc }}
      </p>
    </div>
    <div
      class="plan-button text-white uppercase cursor-pointer flex items-center justify-center"
    >
      <router-link to="widget">
        {{ $t("homepage.homepage-pricing-plan.purchase") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-container {
  width: 258px;
  height: 590px;
  border-radius: 10px;
  border: 1px solid #00aab6;
}

.plan-header {
  background-color: #00aab6;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}

.plan-old-amount {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #f48120;

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 50%;
    border-bottom: 1px solid #f48120;
    width: 62px;
    left: calc(50% - 31px);
    transform: rotate(15deg);
  }

  &::after {
    transform: rotate(-18.93deg);
  }
}

.plan-button,
.plan-old-amount,
.plan-amount {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}

.plan-amount {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #00aab6;
}

.plan-text-content {
  color: #989898;
}

.plan-button {
  width: 172px;
  height: 46px;
  background: #f48120;
  border-radius: 6px;
  position: absolute;
  bottom: 40px;
  left: calc(50% - 86px);
}

</style>
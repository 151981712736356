<template>
  <div
    class="flex items-center justify-center flex-col py-5 px-2.5 card col-span-1 relative mb-12 lg:mb-0"
  >
    <div class="icon flex items-center justify-center">
      <img
        src="@/assets/homepage/card-checkbox.svg"
        alt="Logo"
        class="w-full h-auto"
      />
    </div>
    <h4 class="heading my-3.5 font-bold">{{ card.heading }}</h4>
    <p class="subheading">{{ card.text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  width: 258px;
  height: 230px;
}

.icon {
  width: 50px;
  height: 50px;
  border: 7px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: calc(50% - 25px);

  svg {
    width: 43px;
    min-width: 43px;
    height: 43px;
  }
}

.heading {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
}

.subheading {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  font-family: "Open Sans";
}
</style>
<template>
  <div class="faq-section px-6 py-12 md:py-24">
    <HomepageHeadingSection :heading="$t('homepage.homepage-faq.heading')" />
    <div class="card-container">
      <HomepageFaqCard
        v-for="(item, index) in faqData"
        :key="index"
        :data="item"
      />
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import HomepageFaqCard from "@/modules/homepage/components/homepage-faq-card/HomepageFaqCard.vue";
import HomepageHeadingSection from "@/modules/homepage/components/homepage-heading-section/HomepageHeadingSection.vue";

export default {
  components: {
    HomepageFaqCard,
    HomepageHeadingSection,
  },

  setup() {
    const t = inject("t");
    const faqData = ref([
      {
        heading: t("homepage.homepage-faq.card-heading-1"),
        text: t("homepage.homepage-faq.card-text-1"),
      },
      {
        heading: t("homepage.homepage-faq.card-heading-2"),
        text: t("homepage.homepage-faq.card-text-2"),
      },
      {
        heading: t("homepage.homepage-faq.card-heading-3"),
        text: t("homepage.homepage-faq.card-text-3"),
      },
    ]);

    return {
      faqData,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  max-width: 890px;
  margin: 0 auto;
}

.divider {
  width: 60px;
  margin: 0 auto 50px;
  border-bottom: 1px solid #17b191;
}
</style>
<template>
  <section class="homepage-settings py-16" :style="cssProps">
    <h3 class="homepage-settings-heading">
      {{ $t("homepage.homepage-settings.heading") }}
    </h3>
    <span class="homepage-settings-heading-divider"> </span>

    <div class="flex flex-wrap homepage-settings-container gap-8">
      <div
        v-for="item in settings"
        :key="item.id"
        class="flex items-center homepage-settings-item"
      >
        <div class="homepage-settings-image">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
          >
            <path
              d="M14.911 1.23305C15.5245 1.69931 15.6472 2.55821 15.2054 3.1717C15.2054 3.1717 9.95391 12.1042 7.22999 14.141C7.00913 14.3128 6.56741 14.46 6.29747 14.411C5.56128 14.2637 3.64717 12.7913 3.64717 12.7913L0.70238 10.6073C0.0888829 10.141 -0.0338167 9.28213 0.407901 8.66864L0.898699 8.00606C1.36496 7.39256 2.22385 7.26986 2.83735 7.71158L4.6533 9.06127C5.2668 9.52753 6.12569 9.38029 6.59195 8.7668L12.3097 1.01219C12.776 0.398697 13.6349 0.275998 14.2484 0.717716L14.911 1.23305Z"
              fill="white"
            />
          </svg>
        </div>
        <p class="homepage-settings-text">{{ item.title }}</p>
      </div>
    </div>

    <div class="homepage-settings-button-container">
      <div class="homepage-settings-button cursor-pointer">
        <router-link to="/settings">
          {{ $t("homepage.homepage-settings.button") }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { inject, ref, computed } from "vue";
export default {
  name: "HomepageSettings",

  setup() {
    const t = inject("t");
    const cssProps = computed(() => {
      return {
        background: `linear-gradient(
            0deg,
            rgba(0, 170, 182, 0.9),
            rgba(0, 170, 182, 0.9)
          ), url(${require("@/assets/configuration/settings-image.png")}) no-repeat center center / cover`,
      };
    });
    const settings = ref([
      { id: 1, title: t("homepage.homepage-settings.text-1") },
      { id: 2, title: t("homepage.homepage-settings.text-2") },
      { id: 3, title: t("homepage.homepage-settings.text-3") },
      { id: 4, title: t("homepage.homepage-settings.text-4") },
      { id: 5, title: t("homepage.homepage-settings.text-5") },
      { id: 6, title: t("homepage.homepage-settings.text-6") },
    ]);

    return {
      settings,
      cssProps,
    };
  },
};
</script>

<style lang="scss" scoped>
.homepage-settings {
  color: #f4f4f4;
  background-size: cover;
  background-position: center;
  /* background: linear-gradient(
    0deg,
    rgba(0, 170, 182, 0.9),
    rgba(0, 170, 182, 0.9)
  ); */
  /* url(laughing-freelance-girl-spending-time-at-home-wor-2021-12-09-16-27-21-utc.jpg); */
}

.homepage-settings-container {
  max-width: 1200px;
  margin: 55px auto;
  padding: 0 20px;
}

.homepage-settings-heading {
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}

.homepage-settings-heading-divider {
  display: inline-block;
  width: 60px;
  border-bottom: 4px solid #ffffff;
}

.homepage-settings-image {
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background: #f48120;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-settings-item {
  width: calc(50% - 20px);
  gap: 30px;
}

.homepage-settings-text {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  text-align: left;
}

.homepage-settings-button {
  background: #f48120;
  border-radius: 4px;
  padding: 10px 30px;
  display: inline-block;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}

@media (max-width: 768px) {
  .homepage-settings-item {
    width: 100%;
  }

  .homepage-settings-heading {
    font-size: 28px;
    line-height: 34px;
  }

  .homepage-settings-text {
    font-size: 18px;
    line-height: 22px;
  }

  .homepage-settings-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
</style>
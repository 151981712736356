export const homepageTestimonial = [
  {
    name: "Taylor Crawford",
    text: "I’m very delighted with the service, I have been using it for a week now. Works very well, easy instalation, no code just copy and paste.",
    image: require("@/assets/homepage/testimonial-1.png"),
  },
  {
    name: "Karli O'Sullivan",
    text: "Wow it is a unique service. I got great performance of my team, with only one click my clients are connected with the exact person and department.",
    image: require("@/assets/homepage/testimonial-2.png"),
  },
  {
    name: "Keely Miler",
    text: "I highly recommend it. I work from 3 months with this company and I can say, that their professionalism is a very high level.",
    image: require("@/assets/homepage/testimonial-3.png"),
  },
  {
    name: "Eva Jenkins",
    text: "I am very happy with quality, price and support I can always count on their friendly team. Keep moving forward!!!",
    image: require("@/assets/homepage/testimonial-4.png"),
  },

  {
    name: "Jessika Morgan",
    text: "The services, that this company offers are always exceptional level and are always directed at pleasing their customers and all of their needs. ",
    image: require("@/assets/homepage/testimonial-5.png"),
  },
  {
    name: "John Livigne",
    text: "The personal attitude of the company’s team and the willingness to offer their help always in the best possible way are the things that keep our trust in them. I’m more than happy, that we can work not only as a customer but also as a business partners.",
    image: require("@/assets/homepage/testimonial-6.png"),
  },
  {
    name: "Morgan Sullivan",
    text: "The team of professionals, really has an idea of what the visuals of IT technology are. For us, this company is for sure on the right way!",
    image: require("@/assets/homepage/testimonial-7.png"),
  },
  {
    name: "Joshua Pick",
    text: "High-end service, personal attentiveness and creativity  are the things that ease us and give us confidence, that our work cooperation will be prosperous in the far future.",
    image: require("@/assets/homepage/testimonial-8.png"),
  },
  {
    name: "Robert Bart",
    text: "In every aspect, connected with online services, I know that I have nearby amazing professionals and reliable partners, that I can call friends. I’m wishing them luck I hope that they stay all that attentive and friendly and oriented to their clients.",
    image: require("@/assets/homepage/testimonial-9.png"),
  },
];

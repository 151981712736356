<template>
  <section
    class="text-white relative banner flex flex-col items-center justify-end px-5"
    :style="{
      backgroundImage: `url(${require('@/assets/homepage/hero-image.png')})`,
    }"
  >
    <h2 class="main-heading text-center mb-6">
      {{ $t("homepage.banner.heading") }}
    </h2>

    <h3 class="main-subheading text-2xl text-center mb-6 md:mb-11">
      {{ $t("homepage.banner.subheading") }}
    </h3>
    <p class="text-base">{{ $t("homepage.banner.content") }}</p>
    <p class="text-base">{{ $t("homepage.banner.first-bullet") }}</p>
    <p class="text-base pb-4 md:pb-20">
      {{ $t("homepage.banner.second-bullet") }}
    </p>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.banner {
  min-height: 510px;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-content {
  max-width: 1200px;
  margin: 0 auto;
}

.main-heading {
  font-weight: 700;
  font-size: 52px;
  line-height: 61px;
}

.main-subheading {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}

.main-subheading,
.main-heading {
  font-family: Roboto-Condensed, sans-serif;
  font-weight: 700;
}

@media (max-width: 1024px) {
  .main-heading {
    font-size: 40px;
    line-height: 47px;
  }

  .main-subheading {
    font-size: 26px;
    line-height: 30px;
  }
}
</style>
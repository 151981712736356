<template>
  <main class="relative homepage">
    <Header />
    <Homepage />
    <Footer />
  </main>
</template>

<script>
import Header from "@/modules/homepage/header/Header.vue";
import Footer from "@/modules/homepage/footer/Footer.vue";
import Homepage from "@/modules/homepage/homepage/Homepage.vue";

export default {
  components: {
    Header,
    Footer,
    Homepage,
  },
};
</script>

<style lang="scss" scoped>
.homepage {
  color: #676a6c;
  font-family: "Roboto", sans-serif;
}
</style>
<template>
  <div class="px-6 pt-12 pb-14 testimonial-container">
    <HomepageHeadingSection
      :heading="$t('homepage.homepage-testimonials.testimonials')"
    />
    <div class="testimonial-content">
      <carousel :settings="settings" :breakpoints="breakpoints">
        <slide v-for="(slide, index) in testimonials" :key="index">
          <HomepageTestimonial :data="slide" />
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import HomepageTestimonial from "@/modules/homepage/components/homepage-testimonial/HomepageTestimonial.vue";
import HomepageHeadingSection from "@/modules/homepage/components/homepage-heading-section/HomepageHeadingSection.vue";
import { homepageTestimonial } from "@/mockups/homepage/homepageTestimonial.js";

export default {
  components: {
    HomepageTestimonial,
    HomepageHeadingSection,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  setup() {
    const testimonials = ref(homepageTestimonial);

    const settings = ref({
      itemsToShow: 1.5,
      snapAlign: "center",
    });

    const breakpoints = ref({
      // 700px and up
      700: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: "start",
      },
    });

    return {
      settings,
      breakpoints,
      testimonials,
    };
  },
};
</script>
<style lang="scss">
.testimonial-container {
  background: #f4f4f4;
}

.divider {
  width: 60px;
  margin: 0 auto 50px;
  border-bottom: 1px solid #17b191;
}

.testimonial-content {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
<template>
  <section class="homepage-feature-cards pt-14 pb-12 lg:pb-44 px-6">
    <div class="card-container">
      <HomepageHeadingSection
        :heading="$t('homepage.homepage-feature-cards.heading')"
        :isWhiteBorder="true"
      />
      <div
        class="flex flex-col lg:flex-row flex-wrap items-center lg:justify-between gap-2 lg:gap-6"
      >
        <HomepageFeatureCard
          v-for="(card, index) in cards"
          :key="index"
          :card="card"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { ref, inject } from "vue";
import HomepageFeatureCard from "@/modules/homepage/components/homepage-card/HomepageFeatureCard.vue";
import HomepageHeadingSection from "@/modules/homepage/components/homepage-heading-section/HomepageHeadingSection.vue";

export default {
  components: {
    HomepageFeatureCard,
    HomepageHeadingSection,
  },

  setup() {
    const t = inject("t");

    const cards = ref([
      {
        id: 1,
        heading: t("homepage.homepage-cards.first-card.heading-5"),
        text: t("homepage.homepage-cards.first-card.text-5"),
        icon: require("@/assets/homepage/online-message.svg"),
        type: "audio",
        showCard: true,
      },
      {
        id: 2,
        heading: t("homepage.homepage-cards.first-card.heading-52"),
        text: t("homepage.homepage-cards.first-card.text-52"),
        icon: require("@/assets/homepage/file-picture.svg"),
        type: "file",
        showCard: true,
      },
      {
        id: 3,
        heading: t("homepage.homepage-cards.first-card.heading-6"),
        text: t("homepage.homepage-cards.first-card.text-6"),
        icon: require("@/assets/homepage/working-hour.svg"),
        type: "tracking",
        showCard: true,
      },
      {
        id: 4,
        heading: t("homepage.homepage-cards.first-card.heading-7"),
        text: t("homepage.homepage-cards.first-card.text-7"),
        icon: require("@/assets/homepage/offline-message.svg"),
        type: "integration",
        showCard: true,
      },
      {
        id: 5,
        heading: t("homepage.homepage-cards.first-card.heading-8"),
        text: t("homepage.homepage-cards.first-card.text-8"),
        icon: require("@/assets/homepage/call-recording.svg"),
        type: "routing",
        showCard: true,
      },
      {
        id: 6,
        heading: t("homepage.homepage-cards.first-card.heading-9"),
        text: t("homepage.homepage-cards.first-card.text-9"),
        icon: require("@/assets/homepage/history_icon.svg"),
        type: "instalation",
        showCard: true,
      },
    ]);

    return {
      cards,
    };
  },
};
</script>

<style lang="scss" scoped>
.homepage-feature-cards {
  background: #16aeb8;
  color: #fff;
}

.card-container {
  max-width: 1200px;
  margin: 0 auto;
}

.divider {
  width: 60px;
  margin: 0 auto 50px;
  border-bottom: 1px solid #ffffff;
}
</style>
<template>
  <section>
    <HomepageBanner />
    <HomepageCards />
    <HomepageFeatureCards />
    <HomepagePricingPlans />
    <HomepageSettings />
    <HomepageTestimonials />
    <HomepageFaq />
  </section>
</template>

<script>
import HomepageBanner from '@/modules/homepage/components/homepage-banner/HomepageBanner.vue'
import HomepageCards from '@/modules/homepage/components/homepage-cards/HomepageCards.vue'
import HomepageFeatureCards from '@/modules/homepage/components/homepage-feature-cards/HomepageFeatureCards.vue'
import HomepagePricingPlans from '@/modules/homepage/components/homepage-pricing-plans/HomepagePricingPlans.vue'
import HomepageSettings from '@/modules/homepage/components/homepage-settings/HomepageSettings.vue'
import HomepageTestimonials from '@/modules/homepage/components/homepage-testimonials/HomepageTestimonials.vue'
import HomepageFaq from '@/modules/homepage/components/homepage-faq/HomepageFaq.vue'

export default {
  mounted () {
    let widget = document.createElement('script')
    widget.setAttribute(
      'src',
      'https://widget.xlinx.eu/widget.js?client=ba44e275-0b17-e409-cb0c-cb7f70211700'
    )
    widget.setAttribute('data-name', 'client-widget')
    document.head.appendChild(widget)
  },
  beforeUnmount () {
    const script = document.querySelector('[data-name="client-widget"]')
    const widget = document.querySelector('.widget-button.ripple')
    if (script) script.remove()
    if (widget) widget.remove()
  },
  components: {
    HomepageBanner,
    HomepageCards,
    HomepageFeatureCards,
    HomepagePricingPlans,
    HomepageTestimonials,
    HomepageSettings,
    HomepageFaq
  }
}
</script>

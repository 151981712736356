<template>
  <div class="px-6">
    <div class="py-6 card-container">
      <HomepageHeadingSection
        :heading="$t('homepage.homepage-cards.heading')"
      />
      <div class="flex justify-around flex-wrap lg:grid lg:grid-cols-4 gap-4">
        <HomepageCard
          v-for="(card, index) in cards"
          :key="index"
          :card="card"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import HomepageCard from "@/modules/homepage/components/homepage-card/HomepageCard.vue";
import HomepageHeadingSection from "@/modules/homepage/components/homepage-heading-section/HomepageHeadingSection.vue";

export default {
  components: {
    HomepageCard,
    HomepageHeadingSection,
  },

  setup() {
    const t = inject("t");
    const cards = ref([
      {
        heading: t("homepage.homepage-cards.first-card.heading-1"),
        text: t("homepage.homepage-cards.first-card.text-1"),
      },
      {
        heading: t("homepage.homepage-cards.first-card.heading-2"),
        text: t("homepage.homepage-cards.first-card.text-2"),
      },
      {
        heading: t("homepage.homepage-cards.first-card.heading-3"),
        text: t("homepage.homepage-cards.first-card.text-3"),
      },
      {
        heading: t("homepage.homepage-cards.first-card.heading-4"),
        text: t("homepage.homepage-cards.first-card.text-4"),
      },
    ]);

    return {
      cards,
    };
  },
};
</script>

<style lang="scss" scoped>
.heading {
  color: #23a455;
}

.divider {
  width: 60px;
  margin: 0 auto 50px;
  border-bottom: 1px solid #17b191;
}

.card-container {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
<template>
  <div class="mb-7">
    <div>
      <h4
        class="text-left text-xl font-bold h-36 sm:h-16 question-text px-7 flex items-center"
      >
        {{ data.heading }}
      </h4>
      <p
        class="text-left text-base h-36 sm:h-16 answer-text pl-7 flex items-center"
      >
        {{ data.text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.question-text {
  color: #676a6c;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  background: #f4f4f4;
  border-left: 4px solid #f48120;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
}

.answer-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #676a6c;
  display: flex;
  align-items: center;
  border-left: 4px solid #f4f4f4;
}

@media (max-width: 768px) {
  .question-text {
    font-size: 22px;
    line-height: 26px;
  }

  .answer-text {
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
<template>
  <div class="homepage-testimonial relative">
    <div class="image-container">
      <img :src="data.image" alt="Logo" class="w-16 h-16" />
    </div>
    <h3 v-if="data.name" class="heading">{{ data.name }}</h3>
    <div class="flex items-center justify-center pb-4 pt-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="25"
        viewBox="0 0 31 25"
        fill="none"
      >
        <path
          d="M26.718 0L30.866 3.294C29.4833 4.27 28.2227 5.28666 27.084 6.344C25.9453 7.32 24.9693 8.33667 24.156 9.394C23.3427 10.37 22.692 11.4273 22.204 12.566C21.7973 13.7047 21.5127 14.9247 21.35 16.226L19.886 16.348C20.374 15.2907 20.984 14.5993 21.716 14.274C22.5293 13.8673 23.5053 13.664 24.644 13.664C26.2707 13.664 27.6127 14.1927 28.67 15.25C29.8087 16.226 30.378 17.568 30.378 19.276C30.378 20.9027 29.8087 22.2447 28.67 23.302C27.5313 24.278 26.108 24.766 24.4 24.766C22.5293 24.766 20.984 24.1967 19.764 23.058C18.544 21.838 17.934 19.9673 17.934 17.446C17.934 15.6567 18.1373 13.9487 18.544 12.322C19.032 10.614 19.6827 9.06867 20.496 7.686C21.3093 6.222 22.2447 4.83933 23.302 3.538C24.3593 2.23667 25.498 1.05733 26.718 0ZM8.784 0L12.932 3.294C11.5493 4.27 10.2887 5.28666 9.15 6.344C8.01133 7.32 7.03533 8.33667 6.222 9.394C5.40867 10.37 4.758 11.4273 4.27 12.566C3.782 13.7047 3.49733 14.9247 3.416 16.226L1.952 16.348C2.44 15.2907 3.05 14.5993 3.782 14.274C4.59533 13.8673 5.57133 13.664 6.71 13.664C8.33667 13.664 9.67867 14.1927 10.736 15.25C11.7933 16.226 12.322 17.568 12.322 19.276C12.322 20.9027 11.7527 22.2447 10.614 23.302C9.55667 24.278 8.174 24.766 6.466 24.766C4.59533 24.766 3.05 24.1967 1.83 23.058C0.61 21.838 0 19.9673 0 17.446C0 15.6567 0.203333 13.9487 0.61 12.322C1.098 10.614 1.74867 9.06867 2.562 7.686C3.37533 6.222 4.31067 4.83933 5.368 3.538C6.42533 2.23667 7.564 1.05733 8.784 0Z"
          fill="#17B191"
        />
      </svg>
    </div>
    <p class="text-sm testimonial-text" v-if="data.text">{{ data.text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.homepage-testimonial {
  width: 258px;
  height: 360px;
  background: #ffffff;
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  padding: 50px 20px 20px;
}

.heading {
  font-size: 22px;
  line-height: 26px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
}

.testimonial-text {
  color: #989898;
}

.image-container {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  z-index: 99;
}
</style>
<template>
  <XadminLayout> DASHBOARD </XadminLayout>
</template>

<script>
import XadminLayout from "@/modules/xadmin/layout";

export default {
  name: "xadmin",

  components: {
    XadminLayout,
  },
};
</script>

<style>
</style>